/**
 * 用户相关的请求模块
 */
import request from "./http";

export const deleteAdd = (data) => {
  return request({
    url: `http://lovedoslim.com:8090/chengNow/delete/add`,
    method: "post",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    data,
  });
};
