/**
 * 封装 axios 请求模块
 */
import axios from "axios";
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    /* let token = localStorage.getItem('token');
    
      if (token) {
          config.headers.Authorization = 'Bearer ' + token;
      } */
    for (let key in config.params) {
      if (
        config.params[key] === "" ||
        config.params[key] === null ||
        config.params[key] === undefined ||
        config.params[key] === { undefined: true }
      ) {
        delete config.params[key];
      }
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);
const request = axios.create({
  // baseURL: "http://222.215.154.15:8766", // 基础路径
  baseURL: "https://apis.tianapi.com", // 基础路径
});

export default request;
