<template>
  <div class="del-main-box">
    <div class="del-top-box">删除你的账号</div>
    <div class="del-text-box">
      在你删除账号之前，我们还需要你花一点时间，阅读一下我们账号注销协议，在此协议中我们会详细列出如何删除你的账号，以及如何删除与你账号关联的数据。
    </div>
    <div class="del-privacy-box" @click="toAgreements">阅读《隐私协议》</div>
    <div class="del-center-box">
      <div class="del-center-top">请采取你的行动</div>
      <div class="del-center-input">
        <div class="del-input-text">请输入你的登录账号:</div>
        <input type="text" v-model="dataForm.phone" placeholder="请输入你的登录账号" />
      </div>
      <div class="del-center-input">
        <div class="del-input-text">请输入你的登录密码:</div>
        <input type="password" v-model="dataForm.password" placeholder="请输入你的登录账号" />
      </div>
      <div class="del-center-input">
        <div class="del-input-text">请说明你删除账号的原因:</div>
        <textarea type="text" v-model="dataForm.reason" placeholder="请输入你的登录账号" />
      </div>
      <div class="del-center-input">
        <div class="del-input-text">请留下你的联系电话以便我们与你进一步沟通:</div>
        <input type="text" v-model="dataForm.contactInfo" placeholder="请输入你的登录账号" />
      </div>
    </div>
    <div class="del-center-btn" @click="submit">提交删除申请</div>
    <div class="del-bottom-text">Copyright © 2023-2024 dosilm All Rights Reserved</div>
  </div>
</template>
<script>
import { Swipe, SwipeItem, Grid, GridItem, Search, Toast } from "vant";
import { deleteAdd } from "@/api/index.js";
export default {
  // mixins: [mixinViewModule],
  data() {
    return {
      dataForm: {
        phone: "",
        password: "",
        reason: "",
        contactInfo: "",
      },
    };
  },
  components: {
    [Search.name]: Search,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Toast.name]: Toast,
  },
  created() {
    // this.getIpdatas();
    // this.getWxNews();
  },
  methods: {
    submit() {
      if (this.magistrate()) {
        deleteAdd(this.dataForm).then((res) => {
          if (res.data.code == 200) {
            Toast.success("填写成功");
            let timeout2 = setTimeout(() => {
              this.$router.push({ path: "/deleteAccountIndex" });
            }, 1000);
            console.log(timeout2);
          } else {
            Toast.fail(res.data.msg);
          }
        });
      }else{
        Toast.fail("未完整填写表单");
      }
    },
    toAgreements(){
      this.$router.push({ path: "/agreement" });
    },
    magistrate() {
      if (this.dataForm.phone == "") {
        return false;
      } else if (this.dataForm.phopasswordne == "") {
        return false;
      } else if (this.dataForm.reason == "") {
        return false;
      } else if (this.dataForm.contactInfo == "") {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.del-top-box {
  color: #957ec2;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 15px;
}
.del-text-box {
  padding: 0 15px;
  color: #2d2d2d;
  font-size: 14px;
  text-indent: 2em /* 两个空格的宽度 */;
  text-align: left;
}
.del-privacy-box {
  padding: 0 15px;
  text-align: right;
  color: #957ec2;
  font-size: 16px;
}
.del-center-box {
  padding: 20px 15px;
  width: 100%;
}
.del-center-input {
  padding-top: 20px;
}
.del-input-text {
  text-align: left;
  font-size: 14px;
  color: #2d2d2d;
}
.del-center-input input {
  width: calc(100% - 50px);
  margin-left: -30px;
  height: 30px;
  font-size: 14px;
  padding-left: 15px;
}
.del-center-input textarea {
  width: calc(100% - 50px);
  margin-left: -30px;
  font-size: 14px;
  padding-left: 15px;
  height: 80px;
}
.del-center-top {
  text-align: left;
  font-size: 12px;
  color: #2d2d2d;
}
.del-center-btn {
  width: 40%;
  height: 40px;
  background: #957ec2;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin: 0 auto 50px;
}
.del-bottom-text {
  color: #2d2d2d;
  font-size: 12px;
  position: relative;
  bottom: 5px;
  width: 100%;
  text-align: center;
}
.del-main-box {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
</style>
